@import '/src/styles/index.scss';

.container {
  // Expand grid cell to fill the available space
  // when there is only one section in the widget
  &[data-widget-section-count='1'] {
    --#{$product-namespace}-height: 100%;
    --#{$product-namespace}-widget-height: 100%;
    --#{$product-namespace}-grid-cell-max-height: 100%;
    --#{$product-namespace}-grid-cell-overflow: auto;

    &[data-widget-accreditation-visible='true'] {
      --#{$product-namespace}-accreditation-height: #{rem-calc(36)};
    }
  }

  width: 100%;
  height: _var(--widget-height);
  max-height: _var(--widget-max-height);
  max-width: _var(--widget-max-width);
  padding-top: _var(--widget-padding-top);
  padding-right: _var(--widget-padding-right);
  padding-bottom: _var(--widget-padding-bottom);
  padding-left: _var(--widget-padding-left);
  background-color: _var(
    --widget-background-color,
    _var(--body-background-color)
  );
  font-family: _var(--body-font-family);
  contain: paint; // Descendants of the element don't display outside its bounds
  margin-inline: auto;
  z-index: 1;
}

// Editor styles
.section {
  &--selected {
    outline: 3px solid rgba(#3881f3, 0.75);
    outline-offset: -3px;
    padding: 3px; // Ensure that outline is not covered by child
  }

  &--isSelectable {
    cursor: pointer;
  }
}
