@mixin exk-widget-grid-single-column {
  & {
    display: grid;
    grid-column-gap: _var(--grid-column-gap);
    grid-row-gap: _var(--grid-row-gap);
    grid-template-columns: 100%;
    overflow: auto;
  }
}

@mixin exk-widget-grid {
  @include exk-widget-grid-single-column;

  // Restrict medium devices to 2 columns,
  // as long as they have at least 2 sections
  // and are not restricted to 1 column
  &--mediumUp:not([data-grid-max-columns='1']):not(
      [data-layout-section-count='1']
    ) {
    grid-template-columns: repeat(min(2, _var(--grid-max-columns)), 1fr);
    align-content: start;
  }

  // Restrict large devices to 3 columns
  &--large:not([data-grid-max-columns='1']):not(
      [data-layout-section-count='1']
    ):not([data-layout-section-count='2']) {
    grid-template-columns: repeat(min(3, _var(--grid-max-columns)), 1fr);
  }
}

@mixin exk-widget-grid-cell {
  & {
    max-width: 100%;
    min-height: _var(--grid-cell-min-height);
    max-height: _var(--grid-cell-max-height);
    overflow: _var(--grid-cell-overflow, auto);
  }

  [data-grid-cell-max-height='auto'] & {
    overflow: _var(--grid-cell-overflow, visible);
  }
}

// Use height if provided, otherwise expand
// vertically to the boundaries of the grid cell
// expanding to as much as is possible
@mixin exk-widget-chart-section {
  & {
    height: _var(
      --height,
      _var(--chart-grid-cell-base-height, _var(--grid-cell-max-height))
    );
    overflow: auto;
  }
}

@mixin exk-declare-border-variables {
  & {
    border-top-width: _var(--border-top-width);
    border-right-width: _var(--border-right-width);
    border-bottom-width: _var(--border-bottom-width);
    border-left-width: _var(--border-left-width);
    border-top-style: _var(--border-top-style);
    border-right-style: _var(--border-right-style);
    border-bottom-style: _var(--border-bottom-style);
    border-left-style: _var(--border-left-style);
    border-top-color: _var(--border-top-color);
    border-right-color: _var(--border-right-color);
    border-bottom-color: _var(--border-bottom-color);
    border-left-color: _var(--border-left-color);
  }
}

// Provide a way to declare padding variables for a container
// with an optional minimum padding value
@mixin exk-declare-padding-variables($min-padding: null) {
  & {
    padding-top: if(
      $min-padding != null,
      max(_var(--padding-top), $min-padding),
      _var(--padding-top, 0px)
    );
    padding-right: if(
      $min-padding != null,
      max(_var(--padding-right), $min-padding),
      _var(--padding-right, 0px)
    );
    padding-bottom: if(
      $min-padding != null,
      max(_var(--padding-bottom), $min-padding),
      _var(--padding-bottom, 0px)
    );
    padding-left: if(
      $min-padding != null,
      max(_var(--padding-left), $min-padding),
      _var(--padding-left, 0px)
    );
  }
}

@mixin exk-widget-layout($padding: null) {
  & {
    height: if($padding != null, calc(100% - #{$padding}), 100%);
  }
}
