@import '/src/styles/index.scss';

.container {
  @include exk-widget-layout(_var(--accreditation-height, 0px));

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
}

.header {
  &--mediumUp {
    padding-inline: _var(--padding-left) _var(--padding-right);
  }
}

.main {
  flex: 1 1 auto;
  max-width: 100%;
  @include exk-widget-grid;

  &--mediumUp {
    flex-basis: 53%;
    width: 53%; // NOTE: matches sidebar
  }

  &--large {
    flex-grow: 0;
  }

  &__header {
    padding-inline: _var(--padding-left) _var(--padding-right);
  }
}

.sidebar {
  width: 100%;
  max-width: 100%;
  padding-top: _var(--global-xxlg-padding);
  @include exk-widget-grid-single-column;

  // Make any only-child section
  // sticky within sidebar
  &--medium > *:first-child:last-child {
    position: sticky;
    top: 0;
  }

  &--mediumUp {
    width: unset;
    flex: 1 1 47%;
    width: 47%; // NOTE: matches main
    padding-top: 0;
    border-left: 1px solid $gray-20;
  }

  &--large {
    // Make entire sidebar sticky
    position: sticky;
    top: _var(--global-padding);

    // Create Pill UI
    max-width: rem-calc(450);
    align-self: flex-start; // Prevent streaching container to height
    margin-bottom: _var(--global-xxlg-padding);
    box-shadow: _var(--summary-box-shadow);
    border-radius: rem-calc(6);
    border: 1px solid _var(--border-color, $gray-20);
  }
}

.cell {
  @include exk-widget-grid-cell;
}

//
// Typography
//

.heading {
  margin-bottom: _var(--padding-2_5);
}
