@import 'src/styles/index.scss';

.drawerOverlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: $drawerIndex + 1;
  background-color: rgba(0, 0, 0, 0.8);
}

.drawerContent {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $drawerIndex + 1;
  background-color: $white;
  border-top-left-radius: $global-xlg-radius;
  border-top-right-radius: $global-xlg-radius;
  border: 1px solid $gray-30;
  margin-top: _var(--global-lg-padding);
  display: flex;
  height: auto;
  max-height: 80vh;
  flex-direction: column;
  align-items: center;
}

.drawerHandle {
  margin: _var(--global-padding) auto 0;
  height: rem-calc(8);
  width: rem-calc(100);
  border-radius: $global-radius;
  background-color: $gray-30;
}
